.button-app{
    outline: none;
    margin: 5px;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    text-transform: uppercase;
    background-color: #cecece;
    color: #2d2d2d;;
    border: 1px solid #10101000;
    z-index: 2;
    font-weight: bold; 
    letter-spacing: 0.8px;
    font-size: 14px;
    /* -webkit-box-shadow: 0 10px 6px -6px #000;
    -moz-box-shadow: 0 10px 6px -6px #000;
    box-shadow: 0 10px 6px -6px #000; */
}

.button-app[disabled] {
    opacity: 0.5;
}
.button-app:not([disabled]):hover{
    background-color: #2d2d2d;
    color: #cecece;
}